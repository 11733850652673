<template flex>
  <div class="dashboard">
    <v-app class=" ">
      <div class="inner-container only-heading-part mt-0">
        <v-container fluid class="v-main-content only-simple-content pt-0">
          <template fluid>
            <div id="app">
              <template>
                <div class="d-flex align-center mb-8 mt-8">
                  <v-btn icon @click="goToRules()">
                    <v-icon color="primary" class="">mdi-arrow-left</v-icon>
                  </v-btn>
                  <h5 class="primarybase--text h5--text-xbold">
                    Late Day Winning
                  </h5>
                </div>
                <template flex>
                  <v-form v-model="ldWinningFormValid" ref="ldWinningForm">
                    <v-card elevation="0" variant="outlined">
                      <div class="inner-container only-heading-part">
                        <v-container
                          fluid
                          class="v-main-content only-simple-content"
                        >
                          <template fluid>
                            <div id="app">
                              <v-card
                                elevation="0"
                                class="d-flex align-center mt-4"
                              >
                                <v-row class="">
                                  <v-col cols="12" lg="12" md="12" sm="12">
                                    <v-text-field
                                      label="Late day Winning Name"
                                      placeholder="Name"
                                      class="mr-4"
                                      v-model="name"
                                      required
                                      :rules="[
                                        (v) => (!!v) || 'Name cannot be empty',
                                      ]"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-select
                                      placeholder="Select an App"
                                      label="App"
                                      class=" "
                                      v-model="selectedApp"
                                      :items="appsList"
                                      :loading="loadingApps"
                                      item-text="appName"
                                      item-value="adamId"
                                      required
                                      prepend-inner-icon="mdi-cellphone"
                                      return-object
                                      @change="changeApp"
                                    >
                                      <template v-slot:item="data">
                                        <template>
                                          <v-img
                                            class="rounded-lg"
                                            color="secondary"
                                            :lazy-src="data.item.appIcon"
                                            max-width="30"
                                            max-height="30"
                                            min-width="20"
                                            min-height="20"
                                            :src="data.item.appIcon"
                                          ></v-img>
                                          <v-list-item-content class="ml-2">
                                            <v-list-item-title>
                                              {{ data.item.appName }}
                                            </v-list-item-title>
                                          </v-list-item-content>
                                          <v-label
                                            v-if="data.item.attrExist"
                                            color="secondary"
                                            :disabled="true"
                                            >Attributed
                                          </v-label>
                                          <v-label
                                            v-if="!data.item.attrExist"
                                            color="secondary"
                                            :disabled="true"
                                            >Not Attributed
                                          </v-label>
                                        </template>
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-autocomplete
                                      placeholder="Select campaigns"
                                      label="Campaigns"
                                      class="  "
                                      v-model="selectedCamps"
                                      :items="campsList"
                                      :loading="loadingCamps"
                                      item-text="name"
                                      item-value="id"
                                      required
                                      multiple
                                      validate-on-blur
                                      :rules="[
                                        (v) =>
                                          (!!v && v.length > 0) ||
                                          'Please select atleast 1 campaign',
                                      ]"
                                      prepend-inner-icon="mdi-campaign"
                                      return-object
                                      @change="campaignsSelected"
                                      :disabled="
                                        !appsList || appsList.length === 0
                                      "
                                    >
                                      <template v-slot:item="data">
                                        <template>
                                          <v-list-item-content class="ml-2">
                                            <v-list-item-title>
                                              {{ data.item.name }}
                                            </v-list-item-title>
                                          </v-list-item-content>
                                          <v-label
                                            color="secondary"
                                            :disabled="true"
                                            >{{ data.item.status }}
                                          </v-label>
                                        </template>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-autocomplete
                                      ref="goalsRef"
                                      placeholder="Select Goals"
                                      label="Goals"
                                      class="  "
                                      v-model="selectedGoals"
                                      :items="goalList"
                                      :loading="loadingGoals"
                                      item-text="name"
                                      item-value="_id"
                                      required
                                      prepend-inner-icon="mdi-bullseye"
                                      return-object
                                      multiple
                                      validate-on-blur
                                      :rules="goalSelectionRules"
                                      :disabled="
                                        !appsList || appsList.length === 0
                                      "
                                    >
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" lg="6" md="6" sm="12">
                                    <template>
                                      <label
                                        class="mb-0 greybase--text caption--text d-block"
                                        >Bid Intensity
                                      </label>
                                      <div
                                        class="d-flex align-start w-100 mt-2"
                                      >
                                        <label
                                          class="primary--text whitespace-nowrap mt-2"
                                          >Lose more</label
                                        >
                                        <v-slider
                                          :step="0.1"
                                          :thumb-label="false"
                                          min="-0.4"
                                          max="+0.4"
                                          class="px-4 mt-1"
                                          v-model="bidIntensity"
                                        >
                                        </v-slider>
                                        <label
                                          class="primary--text whitespace-nowrap mt-2"
                                          >Win more</label
                                        >
                                      </div>
                                    </template>
                                  </v-col>

                                  <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-text-field
                                      label="Max bid"
                                      placeholder="max bid"
                                      class="  "
                                      :rules="maxBidRules"
                                      v-model="maxbid"
                                      required
                                      type="number"
                                      max="30"
                                      min="1"
                                    ></v-text-field>
                                  </v-col>

                                  <!-- <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-checkbox
                                      label="Enable Late Day Winning"
                                      color="primarylight"
                                      :value="false"
                                      v-model="enableLateDay"
                                      hide-details
                                      class="ma-1 mt-5"
                                    ></v-checkbox>
                                  </v-col> -->

                                  <v-col cols="12" lg="6" md="6" sm="12">
                                    <template>
                                      <v-flex>
                                        <v-dialog
                                          ref="dialog"
                                          v-model="modal2"
                                          :return-value.sync="time"
                                          persistent
                                          lazy
                                          full-width
                                          width="290px"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-text-field
                                              label="Time to Start Winning"
                                              prepend-inner-icon="access_time"
                                              required
                                              readonly
                                              v-model="hourToStart"
                                              :rules="[
                                                (v) =>
                                                  (!!v && v.length > 0) ||
                                                  'Please select time',
                                              ]"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-time-picker
                                            v-if="modal2"
                                            v-model="hourToStart"
                                            full-width
                                          >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                              text
                                              color="primary"
                                              @click="modal2 = false"
                                              >Cancel</v-btn
                                            >
                                            <v-btn
                                              text
                                              color="primary"
                                              @click="$refs.dialog.save(time)"
                                              >OK</v-btn
                                            >
                                          </v-time-picker>
                                        </v-dialog>
                                      </v-flex>
                                    </template>
                                  </v-col>
                                  <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-autocomplete
                                      placeholder="Select Timezone"
                                      label="Timezone"
                                      class="  "
                                      :items="timeZoneList"
                                      item-text="text"
                                      item-value="value"
                                      required
                                      validate-on-blur
                                      prepend-inner-icon="mdi-earth"
                                      return-object
                                      v-model="timeZone"
                                    >
                                      <template v-slot:item="data">
                                        <template>
                                          <v-list-item-content class="ml-2">
                                            <v-list-item-title>
                                              {{ data.item.value }}
                                            </v-list-item-title>
                                          </v-list-item-content>
                                          <v-label
                                            color="secondary"
                                            :disabled="true"
                                            >{{ data.item.text }}
                                          </v-label>
                                        </template>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </div>
                          </template>
                        </v-container>
                      </div>
                    </v-card>
                  </v-form>
                </template>
              </template>
            </div>
          </template>
          <div class="w-100 d-flex justify-end mt-4">
            <v-btn color="primary" class="px-6" @click="createRules"
              >Save</v-btn
            >
          </div>
        </v-container>
      </div>
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
import { OWN_APPS, APP_METADATA } from '../utils/apptweakqueries';
import { apolloClient } from '../apollo/apollo-client';
import { LSS } from '@core/services';
import { getOwnApps } from '../utils/util';
import {
  GET_CAMPAIGNS_BY_APP,
  GET_GOALS_FROM_APP,
} from 'src/utils/apollo-queries';
import {
  UPSERT_STRATEGIC_VIEW
} from 'src/utils/apollo-mutations';
import { timezones } from 'src/utils/timezones';
import { CREATE_LDW_RULES } from '../utils/arrules-queries';

export default Vue.extend({
  name: 'Overview',
  data() {
    return {
      hourToStart: null,
      menu2: false,
      modal2: false,
      goalList: [],
      loadingGoals: false,
      fullscreenLoader: null,
      ldWinningFormValid: true,
      panel: 0,
      radioGroup: null,
      checkbox: null,
      loadingApps: false,
      loadingCamps: false,
      appsList: [],
      campsList: [],
      timeZoneList: timezones,
      timeZone: {
        value: 'UTC+00:00',
      },
      vSelectRules: [],
      notEmptyRule: [(v) => !!v || 'Cannot be empty'],
      numberRules: [(v) => +v > 0 || 'Value should be greater than 0'],
      maxBidRules: [
        (v) => (+v >= 0.01) || 'Value should be greater than or equal to $0.01',
      ],
      goalSelectionRules: [
        (v) => {
          if (!!v && v.length > 0) {
            if(v.length > 1) {
             
              const idx = v.findIndex(t => t.name === "tapInstalls" || t.name === "viewInstalls" || t.name === "totalInstalls");
              if(idx > -1)   {
                return 'Combination of installs and other goals selection is not allowed.';
              }
            }
            return true
          }
          
          return 'Please select atleast 1 goal';
        },
      ],
      selectedApp: null,
      selectedCamps: [],
      selectedGoals: [],
      maxbid: null,
      enableLateDay: true,
      bidIntensity: null,
    };
  },
  apollo: {},
  watch: {},
  methods: {
    goToRules() {
      return this.$router.push({ path: '/arrules' });
    },
    async validateForm() {
      await this.$refs.ldWinningForm.validate();
      if (!this.ldWinningFormValid) {
        return;
      }
    },
    async getOwnApps() {
      await apolloClient
        .query({
          query: OWN_APPS,
          variables: {
            from: 'KWJ',
            at: Date.now(),
          },
        })
        .then((res) => {
          const apps = res.data.getAllOwnApps;
        })
        .catch((error) => {});
    },
    populateApps() {
      this.appsList = LSS.ownApps;
      this.loadingApps = false;
      // this.appsList.push({
      //     adamId: 123,
      //     appName: '132 Apps',
      //     appIcon: 'mdi-cellphone',
      //     attrExist: false,
      // })
      if (this.appsList.length > 0) {
        this.selectedApp = this.appsList[0];
        this.changeApp(this.appsList[0]);
      }
    },
    async init() {
      this.loadingApps = true;
      if (!LSS.ownApps) LSS.ownApps = [];
      if (LSS.ownApps && LSS.ownApps.length > 0) {
        this.populateApps();
      } else {
        LSS.ownApps = [];
        // await getOwnApps('KWJ', this)
        if (LSS.asaInt) {
          await getOwnApps('KWJ', this);
        } else {
          this.populateApps();
        }
      }
    },
    async changeApp(e) {
      this.campsList = [];
      this.goalList = [];
      this.selectedCamps = [];
      this.selectedGoals = [];
      this.loadingCamps = true;
      await apolloClient
        .query({
          query: GET_CAMPAIGNS_BY_APP,
          variables: {
            appId: this.selectedApp.adamId,
          },
        })
        .then((res) => {
          this.loadingCamps = false;
          const campaigns = res.data.getCampaignsByApp;
          this.campsList = campaigns;
        })
        .catch((error) => {
          this.loadingCamps = false;
        });

      if (this.selectedApp.attrExist) {
        this.loadingGoals = true;
        this.$apollo
          .query({
            query: GET_GOALS_FROM_APP,
            variables: {
              appId: this.selectedApp.adamId,
            },
          })
          .then((res) => {
            this.loadingGoals = false;
            this.goalList = res.data.getGoalsFromApp;
            if(this.goalList.length) { this.goalList.push({_id: 'tapInstalls', name: "tapInstalls"}, {_id: 'viewInstalls', name: "viewInstalls"}, {_id: 'totalInstalls', name: "totalInstalls"} ); }
          })
          .catch((error) => {
            this.loadingGoals = false;
          });
      }
    },
    campaignsSelected(e) {
      // console.log(e)
    },
    async createRules() {
      await this.$refs.ldWinningForm.validate();
      if (!this.ldWinningFormValid) {
        return;
      }
      const req = {
        app: this.selectedApp,
        campaigns: this.selectedCamps,
        goals: this.selectedGoals,
        maxbid: this.maxbid,
        enableLateDay: this.enableLateDay,
        bidIntensity: this.bidIntensity,
        timeZone: this.timeZone,
        hourToStart: this.hourToStart,
        name: this.name
      };
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: CREATE_LDW_RULES,
          variables: {
            data: JSON.stringify(req),
            at: Date.now(),
          },
        })
        .then(async (res) => {
          this.fullscreenLoader.hide();
          const data = res.data.createLateDayWinningRules;
          if (data.successMessage === 'OK') {
            this.$notify.success('Rules created successfully');
            await this.upsertStrategy(data.data);
            return this.$router.push({ path: '/arrules' });
          } else {
            this.$notify.error((data.successMessage) ? data.successMessage : "Unable to create rule, Something went wrong");
          }
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Unable to create rule');
        });
    },
    async upsertStrategy(ruleId) {
      let goals
      const idx = this.selectedGoals.findIndex(t => t.name === "tapInstalls" || t.name === "viewInstalls" || t.name === "totalInstalls");
      if(idx > -1) {
        goals = [];
      } else {
        goals = this.selectedGoals.map(obj => obj._id);
      }
      const campaigns = this.selectedCamps.map(obj => {
        return {
          "appName": this.selectedApp.appName,
          "appId": this.selectedApp.adamId,
          "type": "CAMPAIGN",
          "name": obj.name,
          "id": obj.id
        };
      });

      const data = {
        name: this.name,
        description: 'Strategy created',
        goals: goals,
        targetEntities: campaigns,
        arrRuleId: ruleId
      };

      const result = await this.$apollo.mutate({
        mutation: UPSERT_STRATEGIC_VIEW,
        variables: {
          strategy: data,
        },
      });
   
    },
  },
  mounted() {
    // this.getOwnApps()
    this.init();
    this.$root.$on('apps-refreshed', (data) => {
      this.loadingApps = false;
      this.populateApps();
    });
  },
});
</script>
